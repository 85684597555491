import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { firestoreReducer } from 'redux-firestore';
// import { firebaseReducer } from 'react-redux-firebase';

/* slices */
import connectionsReducer from './slices/connections';
// import authJwtReducer from './slices/authJwt';

// import productReducer from './slices/product';
// import settingsReducer from './slices/settings';
// import calendarReducer from './slices/calendar';
// import pricingRequestReducer from './slices/pricingRequest';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout']
// };

// const authPersistConfig = {
//   key: 'authJwt',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['isAuthenticated']
// };

const rootReducer = combineReducers({
  // firebase: firebaseReducer,
  // firestore: firestoreReducer,

  connections: connectionsReducer,
  // authJwt: persistReducer(authPersistConfig, authJwtReducer)

  // settings: settingsReducer,
  // calendar: calendarReducer,
  // pricingRequest: pricingRequestReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
