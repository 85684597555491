import React from 'react';
import { Box } from 'bumbag';

const EmoDo = props => (
  <Box className="logo" fontFamily="Fredoka One" {...props}>
    EmoDo
  </Box>
);

export default EmoDo;
