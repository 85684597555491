import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Divider,
  DropdownMenu,
  PageWithHeader,
  PageWithSidebar,
  TopNav,
  SideNav,
  Icon,
  Box,
  usePage,
  useBreakpoint,
  classNames
  // levelStyles
} from 'bumbag';
import ConditionalWrap from 'conditional-wrap';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';
import qs from 'query-string';

import useAuth from 'hooks/useAuth';
import NavigationLink from 'components/common/NavigationLink';
import NavigationItem from 'components/common/NavigationItem';
import EmoDo from 'components/common/EmoDo';
import { ROLES } from 'utils/constants';

import Footer from 'components/common/Footer';

import 'css/header.css';
import 'css/common.css';
import 'css/footer.css';
import 'css/keyframes.css';

/* Navigation Menu elements presented here as an array in order to .map the same elements for Desktop and Mobile View */
let mainMenuItems = [
  { key: 'home', name: 'Home', linkTo: '/', content: 'Home', icon: 'home' },
  {
    key: 'emotion_copycat',
    name: 'Copycat',
    linkTo: '/copycat',
    content: 'Games',
    icon: 'gamepad'
  },
  {
    key: 'emotion_quiz',
    name: 'Quiz',
    linkTo: '/quiz',
    content: 'Games',
    icon: 'gamepad'
  },
  {
    key: 'exercises',
    name: 'Exercises',
    linkTo: '/exercises',
    content: 'Exercises',
    icon: 'gamepad'
  }
];

let playerMenuItems = [
  { key: 'home', name: 'Home', linkTo: '/', content: 'Home', icon: 'home' },
  {
    key: 'emotion_copycat',
    name: 'Copycat',
    linkTo: '/copycat',
    content: 'Games',
    icon: 'gamepad'
  },
  {
    key: 'emotion_quiz',
    name: 'Quiz',
    linkTo: '/quiz',
    content: 'Games',
    icon: 'gamepad'
  },
  {
    key: 'mooddiary',
    name: 'Mood Diary',
    linkTo: '/mooddiary',
    content: 'Mood Diary',
    icon: 'gamepad'
  },
  {
    key: 'exercises',
    name: 'Exercises',
    linkTo: '/exercises',
    content: 'Exercises',
    icon: 'gamepad'
  },
  {
    key: 'communicator',
    name: 'Communicator',
    linkTo: '/communicator',
    content: 'Communicator',
    icon: 'gamepad'
  }
];

let carerMenuItems = [
  {
    key: 'home',
    name: 'Home',
    linkTo: '/',
    content: 'Home',
    icon: 'home'
  }
];

function MainLayoutWrapper({ children }) {
  const { user, logout } = useAuth();
  const { sidebar } = usePage();
  const history = useHistory();
  const location = useLocation();
  const { tab: currentTab } = qs.parse(location.search);
  // Hide avatar on user profile page
  const showAvatar = !(location.pathname === `/user/${user?.userId}` && !currentTab);
  const isMobile = useBreakpoint('mobile');
  const isLoggedIn = user?.userId;
  const imageUrl = user?.avatarUrl || '/images/default-avatar.svg';

  async function handleClickLogout() {
    sidebar.toggle();
    history.push('/');
    await logout();
  }

  const closeIcon = {
    viewBoxWidth: 26,
    viewBoxHeight: 26,
    paths: [
      'M15.382 13.0212L25.5059 2.89707C26.1648 2.23853 26.1648 1.17377 25.5059 0.515234C24.8474 -0.143302 23.7826 -0.143302 23.1241 0.515234L12.9999 10.6394L2.87597 0.515234C2.21713 -0.143302 1.15267 -0.143302 0.494134 0.515234C-0.164711 1.17377 -0.164711 2.23853 0.494134 2.89707L10.618 13.0212L0.494134 23.1454C-0.164711 23.804 -0.164711 24.8687 0.494134 25.5273C0.822324 25.8558 1.25384 26.0208 1.68505 26.0208C2.11626 26.0208 2.54748 25.8558 2.87597 25.5273L12.9999 15.4031L23.1241 25.5273C23.4526 25.8558 23.8838 26.0208 24.315 26.0208C24.7462 26.0208 25.1774 25.8558 25.5059 25.5273C26.1648 24.8687 26.1648 23.804 25.5059 23.1454L15.382 13.0212Z'
    ]
  };

  const manuIcon = {
    viewBoxWidth: 26,
    viewBoxHeight: 26,
    paths: [
      'M23.6073 3.26358H2.39266C1.07336 3.26358 0 4.33689 0 5.65787C0 6.97716 1.07336 8.05053 2.39266 8.05053H23.6073C24.9266 8.05053 26 6.97716 26 5.65787C26 4.33689 24.9266 3.26358 23.6073 3.26358Z',
      'M23.6073 10.6065H2.39266C1.07336 10.6065 0 11.6798 0 13.0008C0 14.3201 1.07336 15.3935 2.39266 15.3935H23.6073C24.9266 15.3935 26 14.3201 26 13.0008C26 11.6798 24.9266 10.6065 23.6073 10.6065Z',
      'M23.6073 17.9495H2.39266C1.07336 17.9495 0 19.0229 0 20.3438C0 21.6631 1.07336 22.7364 2.39266 22.7364H23.6073C24.9266 22.7364 26 21.6631 26 20.3438C26 19.0229 24.9266 17.9495 23.6073 17.9495Z'
    ]
  };

  const Sidebar = () => (
    <SideNav className="mobile-menu" backgroundColor="accentTint" height="100%">
      <Icon icon={closeIcon} onClick={sidebar.toggle} />
      {isLoggedIn && (
        <SideNav.Level className="mobile-menu-avatar">
          <NavigationItem onClick={sidebar.toggle} to={`/user/${user.userId}`}>
            <Avatar
              variant="circle"
              src={`${imageUrl}?${new Date().getTime()}`}
              backgroundColor="white"
              cursor="pointer"
              outline="none"
              padding="0"
              _focus={{ outline: 'none' }}
            />
          </NavigationItem>
        </SideNav.Level>
      )}
      <SideNav.Level>
        {(!user || !user.role) &&
          mainMenuItems.map(item => (
            <NavigationItem key={item.key} onClick={sidebar.toggle} to={item.linkTo}>
              {item.name}
            </NavigationItem>
          ))}
        {user.role === 'CARER' &&
          carerMenuItems.map(item => (
            <NavigationItem key={item.key} onClick={sidebar.toggle} to={item.linkTo}>
              {item.name}
            </NavigationItem>
          ))}
        {user.role === 'PLAYER' &&
          playerMenuItems.map(item => (
            <NavigationItem key={item.key} onClick={sidebar.toggle} to={item.linkTo}>
              {item.name}
            </NavigationItem>
          ))}
        {isLoggedIn && (
          <NavigationItem className="log-out" onClick={handleClickLogout}>
            Log out
          </NavigationItem>
        )}
      </SideNav.Level>
    </SideNav>
  );

  const MobileHeader = () => (
    <>
      <Box className="mobile-logo">
        <TopNav.Item use={NavigationLink} underline={false} to="/">
          <EmoDo />
        </TopNav.Item>
      </Box>
      <TopNav.Item>
        <Icon color="#fff" icon={manuIcon} onClick={sidebar.toggle} />
      </TopNav.Item>
    </>
  );

  const DesktopHeader = () => (
    <>
      <TopNav.Section>
        <TopNav.Item
          use={NavigationLink}
          underline={false}
          to="/"
          alignItems="center"
          fontWeight="semibold"
          fontSize="50px"
        >
          <EmoDo />
        </TopNav.Item>
      </TopNav.Section>
      <TopNav.Section className="top-menu">
        {(!user || !user.role) &&
          mainMenuItems.map(item => {
            const className = window.location.pathname === item.linkTo ? 'active' : '';
            return (
              <TopNav.Item key={item.key} use={NavigationLink} to={item.linkTo} className={className}>
                {item.name}
              </TopNav.Item>
            );
          })}
        {user.role === 'CARER' &&
          carerMenuItems.map(item => (
            <TopNav.Item key={item.key} use={NavigationLink} to={item.linkTo}>
              {item.name}
            </TopNav.Item>
          ))}
        {user.role === 'PLAYER' &&
          playerMenuItems.map(item => {
            const className = window.location.pathname === item.linkTo ? 'active' : '';
            return (
              <TopNav.Item key={item.key} use={NavigationLink} to={item.linkTo} className={className}>
                {item.name}
              </TopNav.Item>
            );
          })}

        {isLoggedIn && (
          <TopNav.Section alignItems="center">
            {showAvatar && (
              <DropdownMenu
                className="header-dropdown"
                outline="none"
                _focus={{ outline: 'none' }}
                menu={
                  <React.Fragment>
                    <DropdownMenu.Item use={RouterLink} color="black" to={`/user/${user.userId}`}>
                      Profile
                    </DropdownMenu.Item>
                    <Divider />
                    <DropdownMenu.Item color="danger" onClick={handleClickLogout}>
                      Logout
                    </DropdownMenu.Item>
                  </React.Fragment>
                }
              >
                <Avatar
                  variant="circle"
                  src={`${imageUrl}?${new Date().getTime()}`}
                  backgroundColor="white"
                  marginTop="major-1"
                  cursor="pointer"
                  outline="none"
                  padding="minor-1"
                  _focus={{ outline: 'none' }}
                />
              </DropdownMenu>
            )}
          </TopNav.Section>
        )}
      </TopNav.Section>
    </>
  );

  const Header = () => (
    <TopNav border="none" background="transparent" color="white">
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </TopNav>
  );

  const auth = useAuth();
  const isPlayer = auth.user?.role === ROLES.PLAYER;

  let pageClass = window.location.pathname;
  let url = window.location.href;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pageGame = urlParams.get('id');

  let pageClassNew = pageClass.replace(/[/-]+/g, '');
  if (pageClassNew === '' && isPlayer !== true && auth.user?.role !== 'CARER') {
    pageClassNew = 'home';
  }
  if (pageClassNew === '' && isPlayer !== true && auth.user?.role === 'CARER') {
    pageClassNew = 'home home-carer';
  }
  if (pageClassNew === '' && isPlayer === true) {
    pageClassNew = 'home-login';
  }
  if (showAvatar === false) {
    pageClassNew = 'profile';
  }
  if (currentTab) {
    pageClassNew = 'profile profile-' + currentTab;
  }
  if (url.includes('?id=GAMEID')) {
    console.log(url);
    pageClassNew = pageClassNew + ' game-' + pageGame;
  }

  return (
    <PageWithHeader header={<Header />} className={classNames('page', { [`page-${pageClassNew}`]: pageClassNew })}>
      <ConditionalWrap
        condition={isMobile}
        wrap={children => (
          <PageWithSidebar minHeight="max-content" sidebar={<Sidebar />}>
            {children}
          </PageWithSidebar>
        )}
      >
        {children}
      </ConditionalWrap>
      <Footer />
    </PageWithHeader>
  );
}

export default MainLayoutWrapper;

MainLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
