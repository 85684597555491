import request from '../utils/request';
import parseParams from '../utils/parse-params';

const copycatQuestionInfo = `
  emotionImage {
    imageUrl
    reference
    referenceUrl
  }
  emotionDescription {
    emotion_type
    emotion_id
    sourcefile
    noun
    adjective
    description
    what
    why
    how
  }
`;

export const getCopycatQuestion = params => {
  return request({
    query: `{ getCopycatQuestion${params ? `(${parseParams(params)})` : ''} { ${copycatQuestionInfo} } }`
  });
};

export const putCopycatAttempt = (question, clicks, isGuessed, timeStart) => {
  return request({
    operationName: 'putCopycatAttempt',
    query: `mutation putCopycatAttempt($copycatAttempt: CopycatAttempt) {
              putCopycatAttempt(copycatAttempt: $copycatAttempt)
            }`,
    variables: {
      copycatAttempt: {
        copycatQuestion: question,
        clicks,
        isGuessed: isGuessed,
        timeStart,
        timeDuration: Date.now() - timeStart
      }
    }
  });
};
