import React from 'react';
import { Route, Switch } from 'react-router-dom';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import MainLayoutWrapper from 'components/layouts/MainLayoutWrapper';
import LoadingPage from 'components/common/LoadingPage';
/* redux */
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';

const PageNotFound = React.lazy(() => import('components/common/PageNotFound'));

// Home
//const HomePageContainer = React.lazy(() => import('containers/HomePageContainer'));

const HomePageContainer = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('containers/HomePageContainer')), 2500);
  });
});

// Auth
const AuthContainer = React.lazy(() => import('containers/AuthContainer'));
const SignUpContainer = React.lazy(() => import('containers/SignUpContainer'));

const AuthForgotPassword = React.lazy(() => import('containers/AuthForgotPassword'));
const AuthResetPassword = React.lazy(() => import('containers/AuthResetPassword'));
const Terms = React.lazy(() => import('containers/Terms'));
const Help = React.lazy(() => import('containers/Help'));
const PrivacyPolicy = React.lazy(() => import('containers/PrivacyPolicy'));
const NotFoundPage = React.lazy(() => import('containers/NotFoundPage'));

// Quiz
const QuizContainer = React.lazy(() => import('containers/QuizContainer'));

// Copycat
const CopycatContainer = React.lazy(() => import('containers/CopycatContainer'));

// User
const UserProfileContainer = React.lazy(() => import('containers/users/UserProfileContainer'));

//MoodDiary
const MoodDiaryContainer = React.lazy(() => import('containers/MoodDiaryContainer'));

// Reccomendations
const MoodRecommedationsContainer = React.lazy(() => import('containers/MoodRecommedationsContainer'));
//DrawingCanvas
const CanvasContainer = React.lazy(() => import('containers/CanvasContainer'));

//LogDiary
const MoodLogContainer = React.lazy(() => import('containers/MoodLogContainer'));

//Customisation
const CustomisationContainer = React.lazy(() => import('containers/CustomisationContainer'));

//Exercises hub
const ExerciseCategoryContainer = React.lazy(() => import('containers/ExerciseCategoryContainer'));

//Exercise page
const ExerciseContainer = React.lazy(() => import('containers/ExerciseContainer'));

//Communicator page
const CommunicatorContainer = React.lazy(() => import('containers/CommunicatorContainer'));

// Particle Canvas
const ParticleCanvasContainer = React.lazy(() => import('containers/ParticleCanvasContainer'));

function AuthenticatedRoutes() {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    return <LoadingPage />;
  }
  return (
    <Switch>
      <Route path="/user/:userId" component={UserProfileContainer} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

function MainRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={HomePageContainer} />
      <Route exact path="/login" component={AuthContainer} />
      <Route exact path="/forgot-password" component={AuthForgotPassword} />
      <Route exact path="/reset-password" component={AuthResetPassword} />
      <Route exact path="/signup" component={SignUpContainer} />
      <Route exact path="/quiz" component={QuizContainer} />
      <Route exact path="/copycat" component={CopycatContainer} />
      <Route exact path="/mooddiary" component={MoodDiaryContainer} />
      <Route exact path="/mooddiaryrecommendations" component={MoodRecommedationsContainer} />
      <Route exact path="/draw" component={CanvasContainer} />
      <Route exact path="/log" component={MoodLogContainer} />
      <Route exact path="/customise" component={CustomisationContainer} />
      <Route exact path="/exercises" component={ExerciseCategoryContainer} />
      <Route exact path="/exercise" component={ExerciseContainer} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/help" component={Help} />
        
      <Route exact path="/particle" component={ParticleCanvasContainer} />
      <Route exact path="/communicator" component={CommunicatorContainer} />
      <AuthenticatedRoutes />
      <Route component={NotFoundPage} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        <MainLayoutWrapper>
          <React.Suspense fallback={<LoadingPage />}>
            <MainRoutes />
          </React.Suspense>
        </MainLayoutWrapper>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
