import React from 'react';
//import {PageContent, styled } from 'bumbag';

import 'css/preloader.css';

function LoadingPage() {
  return (
    <div breakpoint="tablet" className="preloader">
      <h2>
        <span>E</span>
        <span>m</span>
        <span>o</span>
        <span>D</span>
        <span>o</span>
      </h2>
      <p>welcomes you</p>
    </div>
  );
}

export default LoadingPage;
