import React from 'react';
import { Box, Columns, Column, Set, useBreakpoint, Link } from 'bumbag';

function Footer({ ...props }) {
  const isMobile = useBreakpoint('max-largeTablet');
  return (
    <Box background="#E28D9D" className="footer">
      <Columns color="white" marginTop={isMobile ? 'major-2' : '0px'} position="relative">
        <Column><Link href="https://aionspectrum.com/">&#169; {new Date().getFullYear()} AIONSPECTRUM</Link></Column>
        <Column>
          <Set spacing={isMobile ? 'major-2' : 'major-4'} justifyContent={isMobile ? 'center' : 'flex-end'}>
            <Box>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </Box>
            <Box>
              <Link href="/terms">Terms of use</Link>
            </Box>
            <Box>
              <Link href="/help">HELP</Link>
            </Box>
          </Set>
        </Column>
      </Columns>
    </Box>
  );
}

export default Footer;
