import { css ,Flex,styled } from 'bumbag';
import { darken, lighten, shade, tint } from 'polished';

const primary = '#FF2349';
const semantic = '#6DD3CE';
const accent = '#FFA69E';

export const ScrollContainer = styled(Flex)`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
  display: none;
  }
  -ms-overflow-style: none; /* IE and Edge *
  scrollbar-width: none; /* Firefox */
  `;

const theme = {
  global: {
    styles: {
      base: css`
        html,
        body {
          background: rgb(222, 113, 139);
          background: linear-gradient(
            100deg,
            rgba(222, 113, 139, 1) 0%,
            rgba(243, 179, 168, 1) 44%,
            rgba(255, 218, 178, 1) 100%
          );
        }
      `
    }
  },
  breakpoints: {
    mobile: 992,
    largeTablet: 900,
    widescreen: 1600,
    extraLarge: 2000
  },
  fonts: {
    importUrls: [
      'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap',
      'https://fonts.googleapis.com/css2?family=Lilita+One&display=swap',
      'https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'
    ],
    default: "Montserrat, 'Fredoka One'",
    heading: 'Montserrat'
  },
  palette: {
    primary50: '#ffe1e9',
    primary100: '#ffb1bf',
    primary200: '#ff7e96',
    primary300: '#ff4c6b',
    primary400: '#ff4c6b',
    primary,
    primary600: '#b4001e',
    primary700: '#810015',
    primary800: '#50000b',
    primary900: '#210002',
    primaryTint: tint(0.9, primary),
    primaryShade: shade(0.9, primary),

    switchGrey: 'rgba(0, 0, 0, 0.25)',
    switchRed: '#EE3C5F',

    semantic: semantic,
    semantic900: darken(0.09, semantic),
    semantic800: '#2C353F',
    semantic700: '#596E80',
    semantic600: '#86A4BF',
    semantic400: '#ADEEE3',
    semantic300: '#CFFFF4',
    semantic200: lighten(0.79, semantic),
    semantic100: lighten(0.09, semantic),
    semanticTint: tint(0.9, semantic),
    semanticShade: shade(0.9, semantic),

    accent: accent,
    accent900: darken(0.09, accent),
    accent800: darken(0.07, accent),
    accent700: darken(0.05, accent),
    accent600: darken(0.03, accent),
    accent400: '#FFBDB7',
    accent300: '#FFD2CE',
    accent200: '#FFE9E7',
    accent100: lighten(0.09, accent),
    accentTint: tint(0.9, accent),
    accentShade: shade(0.9, accent),

    lightBlue100: '#defcfa',
    lightBlue200: '#beefeb',
    lightBlue300: '#9ae1dd',
    lightBlue400: '#74d4ce',
    lightBlue: '#50c8c0',
    lightBlue600: '#37afa7',
    lightBlue700: '#278882',
    lightBlue800: '#17625d',
    lightBlue900: '#033b39',
    lightBlueShade: '#001616',
    lightBlueTint: '#defbfc',

    lightGreen: '#DBEBD7',
    lightOrange: '#FEDCD5',
    lightBlueCopycat: '#B0DFEA',
    lightYellow: '#F5E3A2',

    normalGreen: '#4eae53',

    darkBlue100: '#e0f7ff',
    darkBlue200: '#bfe1f1',
    darkBlue300: '#9acde3',
    darkBlue400: '#75b7d5',
    darkBlue: '#50a3c8',
    darkBlue600: '#3789af',
    darkBlue700: '#286b89',
    darkBlue800: '#174c63',
    darkBlue900: '#052f3e',
    darkBlueShade: '#00111a',
    darkBlueTint: '#e0f3ff',

    yellow100: '#f9e9b6',
    yellow200: '#f3da8b',
    yellow: '#eecc5d',
    yellow400: '#e9bd31',
    yellow500: '#d0a319',
    yellow600: '#a27f11',
    yellow700: '#745b09',
    yellow800: '#463602',
    yellow900: '#1a1200',
    yellowShade: '#1a0800',
    yellowTint: '#fff8de',

    purpleTint: '#ebe8ff',
    purple100: '#c4bdf9',
    purple200: '#9d91ef',
    purple300: '#7664e7',
    purple400: '#5038df',
    purple: '#361fc6',
    purple600: '#29189b',
    purple700: '#1c116f',
    purple800: '#110945',
    purple900: '#06021c',
    purpleShade: '#11021c',

    greyBackground700: '#2C363F',
    greyBackground600: '#86A4BF',
    greyBackground: '#C7DODA',
    greyBackground400: '#E1E6EB',

    text: '#636667'
  },
  Alert: {
    styles: {
      base: {
        color: 'black'
      }
    },
    Content: {
      styles: {
        base: {
          color: 'black'
        }
      }
    }
  },
  Button: {
    styles: {
      hover: {
        background: '#EE3C5F',
        border: '1px solid #EE3C5F'
      },
      base: {
        borderRadius: '54px',
        fontWeight: 'normal',
        fontSize: '16px',
        fontFamily: 'Fredoka One',
        minHeight: '54px'
      }
    },
    variants: {
      transparent: {
        styles: {
          base: {
            background: 'transparent',
            border: '1px solid white',
            color: 'white'
          }
        }
      },
      red: {
        styles: {
          hover: {
            background: 'transparent',
            border: '1px solid #EE3C5F',
            color: '#EE3C5F'
          },
          base: {
            background: '#EE3C5F',
            border: '1px solid #EE3C5F',
            color: 'white'
          }
        }
      },
      lightRed: {
        base: {
          background: 'rgba(238, 60, 95, 0.6)'
        }
      },
      darkGreen: {
        base: {
          background: '#5D9D4D'
        }
      },
      bigRed: {
        base: {
          background: '#EE3C5F'
        }
      },
      bordered: {
        styles: {
          hover: {
            background: '#EE3C5F',
            color: '#fff'
          },
          base: {
            background: 'transparent',
            border: '1px solid #EE3C5F',
            color: 'EE3C5F'
          }
        }
      },
      deselectBadge: {
        styles: {
          base: {
            border: '1px solid white',
            borderRadius: '100%',
            backgroundColor: 'red',
            color: 'white',
            minHeight: '30px',
            padding: '0rem 0.5rem 0rem 0.5rem',
            position: 'relative',
            top: '-100px',
            right: '-70px'
          }
        }
      },

      editBadge: {
        styles: {
          base: {
            border: '1px solid white',
            borderRadius: '100%',
            backgroundColor: 'primary',
            color: 'white',
            minHeight: '40px',
            padding: '0rem 0.5rem 0rem 0.5rem',
            position: 'relative',
            top: '-10px',
            right: '55px'
          }
        }
      },
      favouriteBadge: {
        styles: {
          base: {
            background: 'transparent',
            border: '0px',
            color: 'white',
            minHeight: '30px',
            padding: '0rem 0.5rem 0rem 0.5rem',
            position: 'relative',
            top: '-80px',
            left: '0px',
            boxShadow: 'none'
          }
        }
      },
      unFavouriteBadge: {
        styles: {
          base: {
            background: 'transparent',
            border: '0px',
            color: 'white',
            minHeight: '30px',
            padding: '0rem 0.5rem 0rem 0.5rem',
            position: 'relative',
            top: '-80px',
            left: '0px',
            boxShadow: 'none'
          }
        }
      },
      outlinedWithFill: {
        styles: {
          base: {
            color: "#EE3C5F",
            borderRadius: "10px",
            border: "1px solid #E7898F",
            background: "rgba(238, 60, 95, 0.1)",
            height: "54px",
          },
          hover: {
            color: "#FFFFFF",
          },
          hoveractive: {
            color: "#FFFFFF",
            background: "#EE3C5F",
          },
          focus: {
            border: "1px solid #E7898F",
            boxShadow: "none"
          },
        }
      }
    }
  },
  Box: {
    styles: {
      base: {
        //base parameters for a box
      }
    },
    variants: {
      dashedBox: {
        styles: {
          base: {
            border: '3px dashed',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            objectFit: 'contain',
            width: '100px',
            height: '100px',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1'
          }
        }
      },
      gameBox: {
        styles: {
          base: {
            opacity: '0.7',
            border: '3px solid',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            width: '400px',
            height: '200px',
            objectFit: 'contain',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1'
          }
        }
      },
      iconBox: {
        styles: {
          base: {
            ':hover': {
              opacity: '1.0',
              boxShadow: '0 0 0 2px white'
            },
            opacity: '0.7',
            border: '3px solid',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1',
            marginBottom: '-20px'
          }
        }
      },

      iconBoxInactive: {
        styles: {
          base: {
            opacity: '0.2',
            border: '3px solid',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1',
            pointerEvents: 'none',
            marginBottom: '-20px'
          }
        }
      },

      iconBoxEmpty: {
        styles: {
          base: {
            opacity: '1.0',
            border: '3px dashed',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1',
            marginBottom: '-20px'
          }
        }
      },
      iconBoxLog: {
        styles: {
          base: {
            opacity: '1',
            border: '3px solid',
            borderRadius: '20px',
            borderColor: 'white',
            textAlign: 'center',
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            padding: '1px',
            marginTop: 'major-1',
            marginRight: 'major-1',
            marginBottom: '-20px'
          }
        }
      },

      gridBox: {
        styles: {
          base: {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: 'auto auto auto',
            overflow: 'auto',
            padding: '0px 30px 30px',
            height: '250px'
          }
        }
      },
      colorBox: {
        styles: {
          base: {
            width: '45px',
            height: '45px',
            border: '3px solid',
            borderColor: 'white',
            borderRadius: '5px'
          }
        }
      },

      tabbedBox: {
        styles: {
          base: {
            color: 'white',
            border: '4px solid',
            borderColor: 'white',
            height: '60vh',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
            overflow: 'hidden',
            padding: '10px',
            marginBottom: '50px'
          }
        }
      },

      containerBox: {
        styles: {
          base: {
            color: 'white',
            border: '4px solid',
            borderColor: 'white',
            borderRadius: '15px',
            paddingBottom: '15px',
            marginBottom: '10px'
          }
        }
      },

      logEntryBox: {
        styles: {
          base: {
            background: 'rgba(255, 255, 255, 0.2)',
            borderRadius: '15px',
            paddingBottom: '15px',
            marginRight: '20px',
            marginBottom: '15px'
          }
        }
      },

      checkBoxOn: {
        styles: {
          base: {
            ':hover': {
              opacity: '0.7',
              boxShadow: '0 0 0 2px white'
            },

            textAlign: 'center',
            width: '45px',
            height: '45px',
            border: '3px solid',
            borderColor: 'white',
            borderRadius: '5px'
          }
        }
      }
    }
  },
  Dialog: {
    styles: {
      base: {
        borderRadius: '30px',
        textAlign: 'center'
      }
    },
    Header: {
      styles: {
        base: {
          color: primary,
          textTransform: 'uppercase'
        }
      }
    }
  },
  Spinner: {
    styles: {
      base: {
        textAlign: 'center'
      }
    }
  },
  Input: {
    variants: {
      primary: {
        styles: {
          base: {
            background: '#F1A0A3',
            border: 'none',
            borderRadius: '15px',
            color: 'white'
          },
          placeholder: {
            color: 'white',
            opacity: 0.8
          }
        }
      }
    }
  },
  Select: {
    styles: {
      base: {
        background: '#FFF',
        border: '1px solid #777672',
        borderRadius: '15px',
        color: '#4a4b46',
        width: '100%'
      }
    },
    variants: {
      primary: {
        styles: {
          base: {
            background: '#F1A0A3',
            border: 'none',
            borderRadius: '15px',
            color: 'white',
            width: '100%'
          },
          placeholder: {
            color: 'white',
            opacity: 0.8
          }
        }
      }
    }
  },

  Tabs: {
    List: {
      styles: {
        base: {}
      },
      variants: {
        invisibleTabs: {
          styles: {
            base: {
              opacity: '0%'
            }
          }
        }
      }
    },
    Tab: {
      styles: {
        base: {
          color: 'white',
          fontSize: '18px',
          background: 'rgba(255, 255, 255, 0.2)'
        },
        hover: {
          opacity: '50%',
          background: 'white'
        },
        selected: {
          background: 'white'
        }
      }
    }
  },

  TopNav: {
    Item: {
      styles: {
        hover: {
          color: 'accent'
        }
      }
    }
  },
  ProgressBar: {
    styles: {
      base: {
        background: '#FAF9f9'
      }
    }
  }
};

export default theme;
