import {
  faInfinity,
  faSnowflake,
  faSync,
  faFan,
  faAtom,
  faRocket,
  faSpinner,
  faBahai,
  faWater,
  faCertificate
} from '@fortawesome/free-solid-svg-icons';

// Quiz
export const MAX_ATTEMPTS = 3;

export const GAME_DIFFICULTIES = {
  EASY: 0,
  NORMAL: 1,
  HARD: 2
};

export const GAME_DIFFICULTIES_HUMANISED = {
  [GAME_DIFFICULTIES.EASY]: 'easy',
  [GAME_DIFFICULTIES.NORMAL]: 'normal',
  [GAME_DIFFICULTIES.HARD]: 'hard'
};

export const GAME_MODES = {
  ART: 'art',
  PHOTO: 'photo'
};

export const EMOTION_NAMES = {
  anger: 'angry',
  happiness: 'happy',
  sadness: 'sad',
  surprise: 'surprise',
  fear: 'scared',
  disgust: 'disgust',
  neutrality: 'neutral'
};

// Users
export const ROLES = {
  CARER: 'CARER',
  PLAYER: 'PLAYER'
};

export const EMOJIS = [
  {
    title: 'Happy',
    imgSrc: 'happy.svg'
  },
  {
    title: 'Sad',
    imgSrc: 'sad.svg'
  },
  {
    title: 'Angry',
    imgSrc: 'angry.svg'
  },
  {
    title: 'Crying',
    imgSrc: 'crying.svg'
  },
  {
    title: 'Frustrated',
    imgSrc: 'frustrated.svg'
  },
  {
    title: 'Excited',
    imgSrc: 'partying.svg'
  },
  {
    title: 'Surprised',
    imgSrc: 'surprised.svg'
  },
  {
    title: 'Tired',
    imgSrc: 'tired.svg'
  },
  {
    title: 'Disgusted',
    imgSrc: 'disgusted.svg'
  },
  {
    title: 'Scared',
    imgSrc: 'scared.svg'
  },
  {
    title: 'Loved',
    imgSrc: 'loved.svg'
  },
  {
    title: 'Neutral',
    imgSrc: 'neutral.svg'
  }
];

// Particle-game brushes
export const BRUSHES = [
  {
    title: 'Black Hole',
    imgSrc: 'brush.svg',
    touch: 1,
    icon: faCertificate
  },
  {
    title: 'Infinity',
    imgSrc: 'brush.svg',
    touch: 2,
    icon: faInfinity
  },
  {
    title: 'Rocket',
    imgSrc: 'brush.svg',
    touch: 3,
    icon: faRocket
  },
  {
    title: 'Atom',
    imgSrc: 'brush.svg',
    touch: 4,
    icon: faAtom
  },
  {
    title: 'Flower',
    imgSrc: 'brush.svg',
    touch: 5,
    icon: faFan
  },
  {
    title: 'Donut',
    imgSrc: 'brush.svg',
    touch: 6,
    icon: faSpinner
  },
  {
    title: 'Firework',
    imgSrc: 'brush.svg',
    touch: 7,
    icon: faBahai
  },
  {
    title: 'Tornado',
    imgSrc: 'brush.svg',
    touch: 8,
    icon: faSync
  },
  {
    title: 'Snowflake',
    imgSrc: 'brush.svg',
    touch: 9,
    icon: faSnowflake
  },
  {
    title: 'Creek',
    imgSrc: 'brush.svg',
    touch: 10,
    icon: faWater
  }
];

export const PARTS_OF_SPEECH = new Map([
  [
    'adjective',
    {
      color: '#E9F8FF',
      hoverColor: '#6DD3FF'
    }
  ],
  [
    'verb',
    {
      color: '#E8FFD7',
      hoverColor: '#97E27E'
    }
  ],
  [
    'pronoun',
    {
      color: '#FEFFC6',
      hoverColor: '#FFCC15'
    }
  ],
  [
    'noun',
    {
      color: '#FFECD6',
      hoverColor: '#FFB067'
    }
  ],
  [
    'conjunction',
    {
      color: '#FFFFFF',
      hoverColor: '#E5E5E5'
    }
  ],
  [
    'preposition',
    {
      color: '#FFE3F6',
      hoverColor: '#FF8EA5'
    }
  ],
  [
    'question',
    {
      color: '#F3E8FF',
      hoverColor: '#AC85FF'
    }
  ],
  [
    'adverb',
    {
      color: '#EDD9D4',
      hoverColor: '#CD6E63'
    }
  ],
  [
    'determiner',
    {
      color: '#EAEAEA',
      hoverColor: '#C1C1C1'
    }
  ],
  [
    'emergency',
    {
      color: '#FFE0E0',
      hoverColor: '#FF8C8C'
    }
  ]
]);

// Communicator UI consts
export const DESKTOP_HEIGHT_OFFSET = 410;
export const MOBILE_HEIGHT_OFFSET = 390;
export const TOPIC_MOBILE_HEIGHT_OFFSET = 150;
export const BUTTON_HEIGHT = 54;
