import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';

import request from 'client/utils/request';
// import parseParams from 'client/utils/parse-params';
import { userRecord } from 'client/resources/users';

const connectionsData = `
  connection_code
  initiation_timestamp
  isPending
  isConfirmed
  isCanceled
  isDeleted
  connectedUser {
    ${userRecord}
  }
  responseTimestamp
  refusedByUsername
  refusedByFirstName
  refusedByLastName
  cancelationTimestamp
  canceledByUsername
  canceledByFirstName
  canceledByLastName
`;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  hasError: false,
  errors: {},
  errorList: [],
  connectionList: [],
  generatedCode: '',
  // codeRespondResult: '',
  selectedConnection: null
  // // vehicleData: null
  // currentBrand: '',
  // availableBrands: [],
  // brandList: [],
  // selectedBrand: null,
  // selectedBrandList: []
};

const slice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log(`Oops, Redux Slice "connections" got an error`, action);
      state.isLoading = false;
      // state.error = action;
      state.hasError = true;
      state.errorList = action;
    },

    // GET CONNECTIONS
    getMyConnectionsSuccess(state, action) {
      // console.log('getMyConnectionsSuccess: ', { state, action });
      state.isLoading = false;
      state.connectionList = action.payload;
      /* remove error */
      // delete state.errors.getMyConnections;
      state.error = false;
      // state.errorList = [];
    },
    getMyConnectionsFailure(state, action) {
      // console.log('getMyConnectionsFailure: state.error = ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
      // state.hasError = true;
      // state.errors.getMyConnections = action.payload;
      // state.errorList = action.payload;
    },

    // CREATE CONNECTION CODE
    connectionCodeCreateSuccess(state, action) {
      console.log('connectionCodeCreateSuccess: ', { state, action });
      state.isLoading = false;
      state.generatedCode = action.payload;
      /* remove error */
      // delete state.errors.getMyConnections;
      state.error = false;
    },
    connectionCodeCreateFailure(state, action) {
      console.log('connectionCodeCreateFailure: state.error = ', action.payload);
      state.isLoading = false;
      state.error = action.payload;
      state.generatedCode = '';
    },

    // // RESPOND CONNECTION CODE
    // connectionCodeRespondSuccess(state, action) {
    //   console.log('connectionCodeRespondSuccess: ', { state, action });
    //   state.isLoading = false;
    //   state.codeRespondResult = action.payload;
    //   /* remove error */
    //   // delete state.errors.getMyConnections;
    //   state.error = false;
    // },
    // connectionCodeRespondFailure(state, action) {
    //   console.log('connectionCodeRespondFailure: ', { state, action });
    //   state.isLoading = false;
    //   state.error = action.payload;
    //   state.codeRespondResult = '';
    // },

    clearConnectionsDataSuccess(state) {
      state.selectedBrand = initialState.selectedBrand;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// export const getMyConnections = params => {
//   return request({
//     query: `{ getMyConnections${params ? `(${parseParams(params)})` : ''} { ${connectionsData} } }`
//   });
// };

export function getMyConnections() {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const queryString = `query GetMyConnections {
        getMyConnections { ${connectionsData} }
      }`;

      // const response = await axios.post('/graphql', { query: queryString });
      const response = await request({ query: queryString });
      // console.log('getMyConnections: response = ', response);
      /* if returned errors, then throw an error */
      if (response.data.errors && response.data.errors.length > 0) {
        throw new Error(response.data.errors[0].message);
        // throw new Error(response.data.errors);
      }
      /* if no errors, then proceed */
      dispatch(slice.actions.getMyConnectionsSuccess(response.data.getMyConnections));
    } catch (error) {
      dispatch(slice.actions.getMyConnectionsFailure(error));
    }
  };
}

// export const createConnectionCode = variables => {
//   return request({
//     operationName: 'connectionCodeCreate',
//     query: `mutation connectionCodeCreate {
//       connectionCodeCreate
//     }`
//   });
// };

export const createConnectionCode = () => {
  console.log('createConnectionCode()');
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const queryString = `mutation ConnectionCodeCreate { connectionCodeCreate }`;

      // const response = await axios.post('/graphql', { query: queryString });
      const response = await request({ query: queryString });
      // console.log('connectionCodeCreate: response = ', response);
      /* if returned errors, then throw an error */
      if (response.data.errors && response.data.errors.length > 0) {
        throw new Error(response.data.errors[0].message);
      }
      /* if no errors, then proceed */
      dispatch(slice.actions.connectionCodeCreateSuccess(response.data.connectionCodeCreate));
    } catch (error) {
      dispatch(slice.actions.connectionCodeCreateFailure(error));
    }
  };
};

// export const respondConnectionCode = variables => {
//   return request({
//     operationName: 'connectionCodeRespond',
//     query: `mutation connectionCodeRespond($code: String! $isConfirmed: Boolean!){
//           connectionCodeRespond(code: $code isConfirmed: $isConfirmed)
//         }`,
//     variables
//   });
// };

// export const respondConnectionCode = params => {
//   const { code, isConfirmed } = params;
//   console.log('respondConnectionCode()');
//   return async dispatch => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // const queryString = `mutation ConnectionCodeRespond($code: String! $isConfirmed: Boolean!){
//       //   connectionCodeRespond(code: "${code}" isConfirmed: ${isConfirmed})
//       // }`;
//       // getMyConnections${params ? `(${parseParams(params)})` : ''} { ${connectionsData} 
//       const queryString = `mutation ConnectionCodeRespond($code: String! $isConfirmed: Boolean!){
//         connectionCodeRespond${params ? `(${parseParams(params)})` : ''}
//       }`;

//       // const response = await axios.post('/graphql', { query: queryString });
//       const response = await request({ query: queryString });
//       console.log('connectionCodeRespond: response = ', response);
//       /* if returned errors, then throw an error */
//       if (response.data.errors && response.data.errors.length > 0) {
//         throw new Error(response.data.errors[0].message);
//       }
//       /* if no errors, then proceed */
//       dispatch(slice.actions.connectionCodeRespondSuccess(response.data.connectionCodeRespond));
//     } catch (error) {
//       dispatch(slice.actions.connectionCodeRespondFailure(error));
//     }
//   };
// };
