import axios from 'axios';
import _forOwn from 'lodash/forOwn';
import * as jwtDecode from 'jwt-decode';

import * as users from './resources/users';
import * as quiz from './resources/quiz';
import * as copycat from './resources/copycat';
import * as mooddiary from './resources/mooddiary';
import * as connections from './resources/connections';
import * as words from './resources/words';

import { apiConfig } from '../config';

const CONTENT_TYPE = 'application/json';
const GRAPHQL_ENDPOINT = `${apiConfig.endpoint}/graphql`;

const client = {
  axios: axios.create(),
  endpoint: GRAPHQL_ENDPOINT,
  requestInterceptor: undefined,
  tokens: { session: null },
  saveSession: undefined,
  isRefreshing: false,
  userId: '',
  setCookieIdToken: () => {},
  // Attach resource getters/setters
  users,
  quiz,
  copycat,
  mooddiary,
  connections,
  words,
  setSetCookieIdToken(setCookieIdToken) {
    this.setCookieIdToken = setCookieIdToken;
  },
  setIsRefreshing(isRefreshing) {
    this.isRefreshing = isRefreshing;
  },
  getSaveSession() {
    return this.saveSession;
  },
  setSaveSession(saveSession) {
    this.saveSession = saveSession;
  },
  setup() {
    this.axios = axios.create();
    if (this.axios) {
      this.axios.defaults.headers.post['Content-Type'] = CONTENT_TYPE;
      this.axios.defaults.headers.post.Accept = CONTENT_TYPE;
    }
    this.setRequestInterceptor({});
  },
  /**
   * Set the HTTP request interceptor - This will intercept every HTTP request made using
   * the client send operation
   * @param {object} header
   */
  setRequestInterceptor(headers) {
    this.requestInterceptor = this.axios.interceptors.request.use(
      config => {
        const request = config || {};
        _forOwn(headers, (val, key) => {
          request.headers[key] = val;
        });
        request.headers.Authorization = `Bearer ${this.tokens.session}`;
        return request;
      },
      error => Promise.reject(error)
    );
  },
  getToken(tokenType = 'session') {
    return this.tokens[tokenType];
  },
  setToken(token, tokenType = 'session') {
    this.tokens[tokenType] = token;
  },
  clearTokens() {
    this.tokens = { session: null };
  },
  /**
   * Kill the client - Stop interceptors, clear cookies and user storage
   */
  stop() {
    this.axios.interceptors.request.eject(this.requestInterceptor);
    this.clearTokens();
  },
  /**
   * Checks if the token is valid
   * @param {string} token
   */
  isValidToken(token) {
    if (!token) {
      this.userId = '';
      return false;
    }
    const decodedJwt = jwtDecode(token);
    // console.log('decodedJwt = ', decodedJwt);
    if (decodedJwt.exp >= Date.now() / 1000) {
      this.userId = decodedJwt.sub;
      return true;
    }
    return false;
  }
};

export default client;
