import React from 'react';
import { Link } from 'bumbag';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

function NavigationLink({ to, underline, children, ...props }) {
  let match = useRouteMatch({
    path: to
  });
  return (
    <Link
      color="white"
      use={RouterLink}
      to={to}
      textDecoration="none"
      fontWeight="semibold"
      borderBottom={match?.isExact && underline ? '1px solid white' : 'none'}
      {...props}
    >
      {children}
    </Link>
  );
}

export default NavigationLink;

NavigationLink.defaultProps = {
  underline: false
};
NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  underline: PropTypes.bool
};
