import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as BumbagProvider, ToastManager } from 'bumbag';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import App from 'App';
import { AuthProvider } from 'contexts/AuthContext';
import client from 'client';
import ErrorPage from 'components/common/ErrorPage';
import theme from 'theme';
import * as serviceWorker from 'serviceWorker';

client.setup();

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <BumbagProvider theme={theme}>
        <AuthProvider>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Router>
              <App />
            </Router>
          </ErrorBoundary>
          <ToastManager />
        </AuthProvider>
      </BumbagProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
