import React from 'react';
import { useCookies } from 'react-cookie';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import client from 'client';
import LoadingPage from 'components/common/LoadingPage';

const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['session']);
  const hasSession = client.isValidToken(_get(cookies, 'session.idToken', undefined));
  const saveSession = _get(cookies, 'session.saveSession', undefined) || client.getSaveSession();
  const persistUser = hasSession || saveSession;

  const [state, setState] = React.useState({
    userId: persistUser ? _get(cookies, 'session.userId', undefined) : undefined,
    idToken: persistUser ? _get(cookies, 'session.idToken', undefined) : undefined,
    refreshToken: persistUser ? _get(cookies, 'session.refreshToken', undefined) : undefined,
    email: persistUser ? _get(cookies, 'session.email', undefined) : undefined,
    username: persistUser ? _get(cookies, 'session.username', undefined) : undefined,
    role: persistUser ? _get(cookies, 'session.role', undefined) : undefined,
    avatarUrl: persistUser ? _get(cookies, 'session.avatarUrl', undefined) : undefined,
    saveSession: persistUser ? saveSession : undefined
  });
  if (saveSession) {
    client.setSaveSession(saveSession);
  }
  if (persistUser) {
    client.setToken(_get(cookies, 'session.idToken', undefined));
  } else {
    removeCookie('session', { path: '/', sameSite: true });
  }
  client.setSetCookieIdToken(idToken => setCookie('session', { ...state, idToken }, { path: '/', sameSite: true }));

  if (state && state.userId === null) {
    return <LoadingPage />;
  }

  return <AuthContext.Provider value={[state, setState]}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
