import React from 'react';
import { Heading, PageContent } from 'bumbag';

function ErrorPage() {
  return (
    <PageContent breakpoint="tablet" textAlign="center">
      <Heading use="h3">Oh no! An error occurred</Heading>
    </PageContent>
  );
}

export default ErrorPage;
