import request from '../utils/request';
import parseParams from '../utils/parse-params';

const quizQuestionInfo = `
  emotionImage {
    imageUrl
    reference
    referenceUrl
  }
  emotionDescription {
    emotion_type
    emotion_id
    sourcefile
    noun
    adjective
    description
    what
    why
    how
  }
  questionOptions {
    name
    emoji
    isAnswer
    key
  }
`;

export const getQuizQuestion = params => {
  return request({
    query: `{ getQuizQuestion${params ? `(${parseParams(params)})` : ''} { ${quizQuestionInfo} } }`
  });
};

export const putQuizAttempt = (question, clicks, isGuessed, timeStart) => {
  return request({
    operationName: 'putQuizAttempt',
    query: `mutation putQuizAttempt($quizAttempt: QuizAttempt) {
              putQuizAttempt(quizAttempt: $quizAttempt)
            }`,
    variables: {
      quizAttempt: {
        quizQuestion: question,
        clicks,
        isGuessed: isGuessed,
        timeStart,
        timeDuration: Date.now() - timeStart
      }
    }
  });
};