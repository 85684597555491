import request from '../utils/request';
import parseParams from '../utils/parse-params';

// const moodDiaryInfo = `
// moodDiaryOption{
//   type
//   id
//   name
//   imageUrl
//   tags
// }

// moodDiaryRecord{
//   user_id
//   record_id
//   timestamp
//   gameId
//   logs
// }

// moodDiaryLog{
//   logType
//   logId
//   logTime
// }

// moodDiaryLogInput{
//   logType
//   logId
//   logTime
// }

// moodDiaryOptionInput{
//   type
//   id
//   name
//   imageUrl
//   colour
//   tags
//   imageStringBase64
// }
// `;

// const moodDiaryOptionsInfo = `
//   type
//   id
//   name
//   imageUrl
//   tags
// `;

const moodDiaryRecords = `
  user_id
  record_id
  timestamp
  gameId
  logs{
    logId
    logType
    logTime
  }
`;

export const getMoodDiaryRecords = params => {
  return request({
    query: `{ getMoodDiaryRecords${params ? `(${parseParams(params)})` : ''} { ${moodDiaryRecords} } }`
  });
};

export const getMoodDiaryOptions = params => {
  // console.log('getMoodDiaryOptions(), params = ', params);
  return request({
    query: `{ getMoodDiaryOptions { type, id, name, imageUrl, colour, tags } }`
  });
};

export const updateMoodDiary = combinedLog => {
  return request({
    operationName: 'updateMoodDiary',
    query: `mutation updateMoodDiary($gameId: String, $logs: [MoodDiaryLogInput]) {
              updateMoodDiary(gameId: $gameId, logs: $logs)
            }`,
    variables: {
      gameId: null,
      logs: combinedLog
    }
  });
};

export const createMoodDiaryRecord = combinedLog => {
  return request({
    operationName: 'createMoodDiaryRecord',
    query: `mutation createMoodDiaryRecord($gameId: String, $logs: [MoodDiaryLogInput]) {
      createMoodDiaryRecord(gameId: $gameId, logs: $logs) {record_id}
    }`,
    variables: {
      gameId: null,
      logs: combinedLog
    }
  });
};

export const updateMoodDiaryRecord = (gameId, record_id) => {
  return request({
    operationName: 'updateMoodDiaryRecord',
    query: `mutation updateMoodDiaryRecord($gameId: String, $record_id: String) {
      updateMoodDiaryRecord(gameId: $gameId, record_id: $record_id) {record_id}
    }`,
    variables: {
      gameId: gameId,
      record_id: record_id
    }
  });
};

export const updateMoodDiaryOption = newTile => {
  return request({
    operationName: 'updateMoodDiary',
    query: `mutation updateMoodDiary($updateOptions: [MoodDiaryOptionInput]) {
              updateMoodDiary(updateOptions: $updateOptions)
            }`,
    variables: {
      updateOptions: newTile
    }
  });
};

export const addMoodDiaryOption = newTile => {
  return request({
    operationName: 'addMoodDiaryOption',
    query: `mutation addMoodDiaryOption($optionInput: MoodDiaryOptionInput!) {
      addMoodDiaryOption(optionInput: $optionInput) {id imageUrl}
    }`,
    variables: {
      optionInput: newTile
    }
  });
};

export const deleteMoodDiaryOption = newTile => {
  return request({
    operationName: 'updateMoodDiary',
    query: `mutation updateMoodDiary($deleteOptions: [MoodDiaryOptionInput]) {
              updateMoodDiary(deleteOptions: $deleteOptions)
            }`,
    variables: {
      deleteOptions: newTile
    }
  });
};
