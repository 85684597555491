import request from '../utils/request';
import parseParams from '../utils/parse-params';
import { userRecord } from './users';

const connectionsData = `
  connection_code
  initiation_timestamp
  isPending
  isConfirmed
  isCanceled
  isDeleted
  connectedUser {
    ${userRecord}
  }
  responseTimestamp
  refusedByUsername
  refusedByFirstName
  refusedByLastName
  cancelationTimestamp
  canceledByUsername
  canceledByFirstName
  canceledByLastName
`;

export const getMyConnections = params => {
  return request({
    query: `{ getMyConnections${params ? `(${parseParams(params)})` : ''} { ${connectionsData} } }`
  });
};

export const createConnectionCode = variables => {
  return request({
    operationName: 'connectionCodeCreate',
    query: `mutation connectionCodeCreate {
      connectionCodeCreate
    }`
  });
};

export const respondConnectionCode = variables => {
  return request({
    operationName: 'connectionCodeRespond',
    query: `mutation connectionCodeRespond($code: String! $isConfirmed: Boolean!){
          connectionCodeRespond(code: $code isConfirmed: $isConfirmed)
        }`,
    variables
  });
};
