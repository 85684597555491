import request from '../utils/request';

export const wordData = `
    word_id
    category
    emoji
    selected_number
    part_of_speech
`;

export const getWordList = (userId, category) => {
  return request({
    query: `query getCommunicatorWords{
      getCommunicatorWords(user_id:"${userId || 'no userId (make default in api)'}", category:"${category}"){
            ${wordData}
        }
        }`
  });
};

export const getAllWords = userId => {
  return request({
    query: `query getCommunicatorWords{
      getCommunicatorWords(user_id:"${userId || 'no userId (make default in api)'}"){
            ${wordData}
        }
        }`
  });
};

export const createWord = ({userId, word}) => request({
  query: `mutation updateCommunicatorWords($userId: String, $addWords: [CommunicatorWordInput] ) {
    updateCommunicatorWords(
      targetUserId: $userId, 
      addWords: $addWords) {
      words { ${wordData} }
    }
  }`,
  variables: {
    userId,
    addWords: [{
      word_id: word,
    }]
  }
})
