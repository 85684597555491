import request from '../utils/request';

export const userRecord = `
  user_id
  username
  email
  email_verified
  firstName
  lastName
  avatarUrl
  birthday
  gender
  country
  countryCode
  role
  score
  scoreCopycat
  scoreQuiz
  levelCopycat
  levelQuiz
  quizDifficultySetting
  quizImageTypeSetting
  quizDifficulty
  quizMode
  copycatDifficulty
`;

export const quizDetails = `quiz{      
  dates{
    date,
    timeTotal,
    emotions{
      emotion,
      timeTotal,
      rightAnswerCount,
      wrongAnswerCount,
      skippedAnswerCount
    },
    difficulties{
      difficulty,
      rightAnswerCount,
      wrongAnswerCount,
      skippedAnswerCount,
      timeTotal
  }
  }
}
copycat{
  dates{
    date,
    timeTotal,
    emotions{
      emotion,
      timeTotal,
      rightAnswerCount,
      wrongAnswerCount,
      skippedAnswerCount
    },
    difficulties{
      difficulty,
      rightAnswerCount,
      wrongAnswerCount,
      skippedAnswerCount,
      timeTotal
  }

  }
}`;

const loginOutput = `
  idToken,
  accessToken,
  refreshToken,
  iat,
  exp,
  auth_time,
  user {
    user_id,
    username,
    email,
    role
  }
`;

// export const login = variables => {
//   // console.log('users.login: variables = ', variables);
//   return request({
//     query: `query Login($username: String, $email: String, $password: String) {
//       login(username: $username, email: email, password: $password) { ${loginOutput} }
//     }`,
//     variables
//   });
// };

export const login = variables => {
  // console.log('users.login: variables = ', variables);
  const { username, email, password } = variables;

  const queryString = email
    ? `query Login { 
    login(email: "${email}",  password: "${password}") { ${loginOutput} } 
  }`
    : `query Login { 
    login(username: "${username}",  password: "${password}") { ${loginOutput} } 
  }`;
  // console.log('queryString = ', queryString);

  return request({ query: queryString });
};

export const refreshSession = variables => {
  const { refreshToken } = variables;
  return request({
    query: `query RefreshSession { 
      refreshSession(refreshToken: ${refreshToken}) { ${loginOutput} } 
    }`,
    variables
  });
};

export const createUser = variables => {
  // console.log('users.createUser: variables = ', variables);
  // return request({
  //   query: `mutation Register($username: String, $password: String, $email: String, $role: UserRole) {
  //     login(username: $username, password: $password, email: $email, role: $role)
  //   }`,
  //   variables
  // });

  const { username, password, email, role } = variables;
  return request({
    query: `mutation Register { 
      createUser(username: "${username}", password: "${password}" email: "${email}", role: ${role}) 
    }`
  });
};

export const forgotPassword = variables => {
  const { username, email } = variables;
  return request({
    query: `query ForgotPassword { 
      forgotPassword(username: "${username}", email: "${email}") 
    }`
  });
};

export const resetPassword = variables => {
  const { username, email, confirmationCode, newPassword } = variables;
  const queryString = username
    ? `mutation resetPassword { 
    resetPassword(username: "${username}", confirmationCode: "${confirmationCode}", newPassword: "${newPassword}")
  }`
    : `mutation resetPassword { 
    resetPassword(email: "${email}", confirmationCode: "${confirmationCode}", newPassword: "${newPassword}")
  }`;
  console.log('resetPassword: queryString = ', queryString);

  return request({ query: queryString });
};

export const getUser = () => {
  return request({ query: `{ getUserInfo { ${userRecord} } }` });
};

export const updateUser = variables => {
  return request({
    operationName: 'updateUserInfo',
    query: `mutation updateUserInfo($userInput: UpdateUserInput){
          updateUserInfo(userData: $userInput){
            ${userRecord}
          }
        }`,
    variables: { userInput: variables }
  });
};

export const updateUserAvatar = variables => {
  return request({
    operationName: 'uploadProfilePicture',
    query: `mutation uploadProfilePicture($base64: String!){
        uploadProfilePicture(base64: $base64)
      }`,
    variables: { base64: variables }
  });
};

export const updateUserCheckInData = (checkInFrequency, emotions) => {
  return request({
    query: `mutation UpdateEmotionCheckIn {
      updateEmotionCheckIn(checkInFrequency: ${checkInFrequency}, emotions: [{emotion: "${emotions.emotion}", intensity: ${emotions.intensity}}])
     }`
  });
};

export const getUserStatistics = (userId, timestampFrom, timeStampTo) => {
  return request({
    query: `query getUserStatistics{
      getUserStatistics(userId:"${userId}"){
        ${quizDetails}
      }
    }
  `
  });
};

export const getEmotionalCheckinData = userId => {
  return request({
    query: `query GetEmotionCheckIns {
      getEmotionCheckIns(userId:"${userId}", timeStart:1){
       checkInFrequency
       checkInLastUpdate
       checkIns {
        timestamp
        emotions {
         emotion
         intensity
        }
       }
      }
     }`
  });
};

export const deleteConnection = (connectionCode, timeStamp) => {
  return request({
    query: `mutation connectionCodeDelete {
      connectionCodeDelete(code: "${connectionCode}", initiationTimestamp: ${timeStamp})
     }`
  });
};
