import _get from 'lodash/get';
import client from '../index';

import { useCookies } from 'react-cookie';

async function refreshSession(refreshToken) {
  console.log('refreshSession: refreshToken = ', refreshToken);
  const refreshSessionResponse = await client.users.refreshSession({ refreshToken });
  return refreshSessionResponse.idToken;
}

export default async operation => {
  try {
    if (!client.isRefreshing && client.saveSession && !client.isValidToken(client.getToken())) {
      client.setIsRefreshing(true);
      // const refreshedIdToken = await refreshSession();

      /* We need refreshToken to refreshSession and it should be saved in cookies in AuthContext.js */
      const [cookies] = useCookies(['session']);
      console.log('cookies = ', cookies);
      const refreshToken = cookies.session.refreshToken;
      const refreshedIdToken = await refreshSession(refreshToken);

      client.setCookieIdToken(refreshedIdToken);
      client.setIsRefreshing(false);
    }
    const data = await client.axios.post(client.endpoint, JSON.stringify(operation));
    return _get(data, 'data', {});
  } catch (error) {
    throw _get(error, 'response.data', {});
  }
};
