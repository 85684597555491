import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import client from 'client';
import useAuth from 'hooks/useAuth';

const useIsAuthenticated = () => {
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = React.useState(client.isValidToken(client.tokens.session));
  const [cookies] = useCookies(['session']);
  const { deleteSession } = useAuth();

  React.useEffect(() => {
    if (!client.isValidToken(client.getToken())) {
      deleteSession();
      history.push(
        `/login${
          window.location.pathname ? `?forwardTo=${window.location.pathname}${window.location.search || ''}` : ''
        }`
      );
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [cookies, deleteSession, history]);

  return isAuthenticated;
};

export default useIsAuthenticated;
