import React from 'react';
import { SideNav } from 'bumbag';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

function NavigationItem({ to, children, ...props }) {
  let match = useRouteMatch({
    path: to
  });
  return (
    <SideNav.Item
      textTransform="uppercase"
      use={RouterLink}
      to={to}
      isActive={match?.isExact ? true : false}
      {...props}
    >
      {children}
    </SideNav.Item>
  );
}

export default NavigationItem;

NavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};
