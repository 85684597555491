import { useContext } from 'react';
import { useCookies } from 'react-cookie';
// import _get from 'lodash/get';

import client from 'client';
import { AuthContext } from 'contexts/AuthContext';

const useAuth = () => {
  const [state, setState] = useContext(AuthContext);
  const [, setCookie, removeCookie] = useCookies(['session']);

  /* ---------------------- LOG IN (SIGN IN) ------------------------------------ */
  async function logIn(usernameOrEmail, password, saveSession) {
    let email;
    let username;
    if (usernameOrEmail.includes('@')) email = usernameOrEmail;
    else username = usernameOrEmail;
    console.log({ username, email });

    let authDetails;
    let authErrors;
    try {
      const loginResponse = username
        ? await client.users.login({ username, password })
        : await client.users.login({ email, password });
      // console.log('loginResponse = ', loginResponse);

      if (loginResponse && loginResponse.errors && loginResponse.errors.length > 0) {
        authErrors = loginResponse.errors;
        console.log('Login Error: ', authErrors[0].message);
        authDetails = {};
      } else if (loginResponse && loginResponse.data && loginResponse.data.login) {
        const loginData = loginResponse.data.login;
        authDetails = {
          isAuthenticated: true,
          idToken: loginData.idToken,
          accessToken: loginData.accessToken,
          refreshToken: loginData.refreshToken,
          userId: loginData.user.user_id,
          email: loginData.user.email,
          username: loginData.user.username,
          role: loginData.user.role,
          saveSession
        };
      }
      /* It is needed to setUser here, so that next call "await client.users.getUser()" would have the JWT attached to Headers */
      setUser({ ...authDetails, authErrors, saveSession });
    } catch (err) {
      console.error('Failed to login. ', err);
      throw new Error('Failed to login. ', err);
    }

    /* !! Apparently at this point Headers have to have the JWT token already, otherwise getUser (aka getUserInfo) recieves an error */
    if (!authErrors) {
      const userResponse = await client.users.getUser();
      // console.log('userResponse = ', userResponse);
      const userAvatarUrl =
        userResponse && userResponse.data && userResponse.data.getUserInfo && userResponse.data.getUserInfo.avatarUrl
          ? userResponse.data.getUserInfo.avatarUrl
          : undefined;

      setUser({ ...authDetails, authErrors, avatarUrl: userAvatarUrl, saveSession });
    }

    return { ...authDetails, authErrors, saveSession };
  }

  /* ---------------------- CREATE USER (SIGN UP) ------------------------------------ */
  async function createUser(data) {
    const { username, password, email, role } = data;
    if (!username || !password || !email) {
      throw new Error('Email, username and password required to create a new account');
    }

    const createResponse = await client.users.createUser({ username, password, email, role });
    console.log('createResponse = ', createResponse);
    return createResponse;

    // return await client.users.createUser({ username, password, email, role });
  }

  /* ---------------------- LOG OUT (SIGN OUT) ------------------------------------ */
  async function logout() {
    deleteSession();
  }

  /* ---------------------- Send FORGOT PASSWORD Request (To Email) ------------------------------------ */
  async function forgotPassword(data) {
    console.log('forgotPassword : data = ', data);
    const { username, email } = data;
    if (!username && !email) {
      throw new Error('Email or username are required to send verification code.');
    }

    let authErrors;
    try {
      const forgotPasswordResponse = await client.users.forgotPassword({ username, email });
      console.log('forgotPasswordResponse = ', forgotPasswordResponse);
      if (forgotPasswordResponse && forgotPasswordResponse.errors && forgotPasswordResponse.errors.length > 0) {
        authErrors = forgotPasswordResponse.errors;
        console.log('forgotPassword Error: ', authErrors[0].message);
        setUser({ authErrors });
      }
    } catch (err) {
      console.log('Failed to forgotPassword.', err);
      authErrors = err;
      setUser({ authErrors: err });
      // throw new Error('Failed to send forgot password request.', err);
    }

    return { authErrors };
  }

  /* ---------------------- RESET PASSWORD ------------------------------------ */
  async function resetPassword(data) {
    console.log('resetPassword : data = ', data);
    const { username, email, confirmationCode, newPassword } = data;
    if (!username && !email) {
      throw new Error('Email or username are required to send verification code.');
    }

    let authErrors;
    try {
      const resetPasswordResponse = await client.users.resetPassword({
        username,
        email,
        confirmationCode,
        newPassword
      });
      console.log('resetPasswordResponse = ', resetPasswordResponse);
      if (resetPasswordResponse && resetPasswordResponse.errors && resetPasswordResponse.errors.length > 0) {
        authErrors = resetPasswordResponse.errors;
        console.log('resetPassword Error: ', authErrors[0].message);
        setUser({ authErrors });
      }
    } catch (err) {
      console.error('Failed to resetPassword.', err);
      authErrors = err;
      setUser({ authErrors: err });
      console.log('return back errors as { authErrors: ... } ', err.errors);
      return { authErrors: err.errors };
      // throw new Error({ errors: err.errors });
      // throw new Error('Failed to send forgot password request.', err);
      // throw new Error(`Failed to resetPassword ${err.errors[0].message}`);
    }

    return { authErrors };
  }

  function setUser(userAuth) {
    setState(userAuth);
    setCookie('session', userAuth, { path: '/', sameSite: true });
    if (userAuth.saveSession) {
      client.setSaveSession(userAuth.saveSession);
    }
    if (!client.tokens.session) {
      client.setToken(userAuth.idToken);
    }
  }

  function deleteSession() {
    client.setSaveSession(false);
    setState({});
    removeCookie('session', { path: '/', sameSite: true });
    client.stop();
  }

  return {
    logIn,
    createUser,
    logout,
    forgotPassword,
    resetPassword,
    deleteSession,
    user: state,
    setUser: setState
  };
};

export default useAuth;
